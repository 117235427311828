import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppBarItemTypeET } from '@otris/kendo-ui';
import {
	IError,
	IErrorHandler,
	IErrorHandlerToken,
	IPDAccessServiceToken,
} from '@otris/ng-core-shared';
import {
	IPrivacyNgModuleInfoService,
	IPrivacyNgModuleService,
	ITitleService } from '@otris/privacy-ng-lib';
import {
	ILocalizationService,
	LanguageCodeET,
	IPDAccessService,
	ILanguage
} from "@otris/ng-core-types";
import { LocalizationServiceToken } from '@otris/ng-core';

@Component({
	selector: 'giz-main',
	template: `
		<div class="main-container pg-main-main-container">
			<div class="header pg-main-header">
				<!-- <img class="image" src="assets/images/privacy48.png"/> -->
				<div class="logo-container">
					<img class="image" src="assets/images/giz.png"/>
				</div>
				<div class="title-container">
					<div class="title">{{ title$ | async }}</div>
				</div>
				<div class="logo-container">
				</div>

				<otris-app-bar class="app-bar" themeColor="inherit">
					<otris-app-bar-item [itemType]="appBarItemTypeET.Spacer"></otris-app-bar-item>
					<otris-app-bar-item [itemType]="appBarItemTypeET.Section">
						<otris-button *ngFor="let lang of languages" [toggleable]="true" [flat]="true" [label]="lang.shortName"
							[selected]="lang.code==currentLanguage.code" (selectedChanged)="onLanguageButtonSelectionChanged($event[1], lang)">
						</otris-button>
					</otris-app-bar-item>
					<otris-app-bar-item [itemType]="appBarItemTypeET.Spacer"></otris-app-bar-item>
				</otris-app-bar>
			</div>
			<div class="content" *ngIf="currentLanguage.code == languageCodeET.de">
				<div class="item">
					<b>Herzlich willkommen!</b><br>
					Das Datenschutz-Portal der GIZ ermöglicht als Online-Service die Reduzierung der mail- und dokumentenbasierten Kommunikation hin zur digitalen Kollaboration.<br>
					Sofern Sie bereits wissen, wie sich Ihr Fall der Verarbeitung von personenbezogenen Daten entsprechend des <a href="{{decisionTreeDeUrl}}" title="Entscheidungsbaum (IDA)" target="_blank">Entscheidungsbaums</a> gestaltet, können Sie direkt starten! <br>
					Alle weiteren Informationen zum Thema Datenschutz finden Sie weiterhin auf der <a href="{{IdaPageDeUrl}}" title="IDA" target="_blank">IDA-Seite</a> des Datenschutzmanagements!
					<div class="news" *ngIf="news_de">
						{{news_de}}
					</div>
				</div>

				<div class="item">
					<p>Sie haben eine "Datenschutzanfrage"?
						<br>
						<a href="{{enquiryModuleUrl}}" title="Datenschutzanfrage stellen" target="_blank">HIER ENTLANG</a>
					</p>
					Über das <b>Onlineformular "Datenschutzanfrage"</b> können Sie uns gerne Ihre Fragen senden, wie
					<ul>
						 <!--
						<li><b>allgemeine Anfragen</b> zum Datenschutz</li>
						<li>Anfragen und Anmeldungen zu GIZ-Umfragen mit <b>askallo</b></li>
						<li><b>Prüfung</b> von Datenschutz-Dokumenten, wie bspw. <b>Datenschutzhinweise</b> und <b>Einwilligungen</b></li>
						<li><b>Prüfung</b> der <b>AuV</b> und <b>TOM</b> von Bietern (vor Vertragsabschluss)</li>
						<li><b>Beratung</b> zu <b>ToR</b> und <b>AuV</b></li>
						<li><b>allgemeine <span class="underline">projektbezogene</span> Anfragen</b> zum Datenschutz <b>(bitte Projektnummer angeben*)</b></li>
						<li><b><span class="underline">projektbezogene</span> Beratung</b> zu <b>ToR</b> und <b>AuV (bitte Projektnummer angeben*)</b></li>
						-->
						@for (kategorie of anfragenKategorienDe; track kategorie) {
							 <li>{{kategorie}}</li>
						}
					</ul>
					<div>
						<span class="underline">* diese Beratung erfolgt durch das <a href="{{enquiryModuleDataHelpDeskDeUrl}}" title="Data Help Desk" target="_blank">Data Helpdesk</a> (FMB). Bitte beachten Sie, dass Anfragen mit einem Mindestbudget von 3 Stunden ZAS verbunden sind.</span>
					</div>
					<p>
						<a href="{{enquiryModuleStepByStepDeUrl}}" title="Anleitung Datenschutzanfrage" target="_blank">Hier</a> finden Sie die Schritt-für-Schritt-Anleitung zum Onlineformular Datenschutzanfrage.
					</p>
				</div>

				<div class="item">
					<p>Sie möchten eine "VVT-Meldung" einreichen?
						<br>
						<a href="{{reportingModuleUrl}}" title="VVT anlegen" target="_blank">HIER ENTLANG</a>
					</p>
					Über das <b>Onlineformular "Meldung von Verarbeitungen personenbezogener Daten“</b>, werden erforderliche Angaben bei der Meldung einer Verarbeitung für das VVT der GIZ eingetragen und dokumentiert.
					<br>
					<ul>
						<li>Die <b> <a href="{{reportingModuleResponsiblePartyDeUrl}}" title="IDA" target="_blank">verantwortliche Stelle</a></b>
						füllt <b>vor Beginn</b> der Verarbeitung von personenbezogenen Daten die Meldung zum Verzeichnis der Verarbeitungstätigkeiten (VVT) aus. Dies ist eine gesetzlich vorgeschriebene Dokumentation. <b>Relevante Dokumente</b> können dazu im Onlineformular hochgeladen werden.
						</li>
					</ul>
					<p>
						<a href="{{reportingModuleStepByStepDeUrl}}" title="Anleitung VVT-Meldung" target="_blank">Hier</a> finden Sie die Schritt-für-Schritt-Anleitung zum Onlineformular VVT-Meldung.
					</p>
				</div>
			</div>

			<div class="content" *ngIf="currentLanguage.code == languageCodeET.en">
				<div class="item">
					<b>Welcome!</b><br>
					<b>The Data Privacy Portal</b>, as an online service for GIZ worldwide, enables the reduction of mail and document based communication towards digital collaboration.<br>
					Provided that you already know how your personal data processing case is structured according to the <a href="{{decisionTreeEnUrl}}" title="decision tree" target="_blank">decision tree</a>, you can start directly!<br>
					You can still find all further information on the topic of data protection on the <a href="{{IdaPageEnUrl}}" title="IDA" target="_blank">IDA page</a> of the data privacy management!
					<div class="news" *ngIf="news_en">
						{{news_en}}
					</div>
				</div>

				<div class="item">
					<p>Do you have a Data Privacy Request?<br>
						<a href="{{enquiryModuleUrl}}" title="Data Privacy Request" target="_blank">CLICK HERE</a>
					</p>
					Via the online form "Data Privacy Request" you can send your request to the DSM team. For example:
					<ul>
						 <!--
						<li><b>general inquiries</b> about data privacy</li>
						<li>inquiries and registrations for GIZ surveys with <b>askallo</b></li>
						<li><b>review</b> of data privacy documents, such as <b>privacy statements</b> and <b>consents</b></li>
						<li>contractor <b>review: AuV</b> and <b>TOM</b> (before conclusion of contract)</li>
						<li><b>advice</b> on <b>ToR</b> and <b>AuV</b></li>
						<li><b>general <span class="underline">project related</span> inquiries</b> about data privacy (please indicate project number*)</li>
						<li><b>advice on <span class="underline">project related</span> ToR</b> and <b>AuV</b> (please indicate project number*)</li>
						-->
						 @for (kategorie of anfragenKategorienEn; track kategorie) {
							 <li>{{kategorie}}</li>
						}
					</ul>
					<div>
						<span class="underline">* Consulting is provided by the <a href="{{enquiryModuleDataHelpDeskEnUrl}}" title="Data Help Desk" target="_blank">Data Helpdesk</a> (FMB). Please note that requests come with a minimum commitment of 3 hours of ZAS.</span>
					</div>
					<p>
						<a href="{{enquiryModuleStepByStepEnUrl}}" title="Instruction Data request" target="_blank">Here</a> you find the step-by-step instruction to the online form Data request.
					</p>
				</div>

				<div class="item">
					<p>You need to fill in a “Notification to the VVT”?<br>
						<a href="{{reportingModuleUrl}}" title="create VVT" target="_blank">CLICK HERE</a>
					</p>
					The online form <b>"report data processing of personally identifiable information"</b> is used to enter and document the information required  when notifying a processing of personal data for the <b>VVT</b> of GIZ.<br>
					<ul>
						<li>
							The <a href="{{reportingModuleResponsiblePartyEnUrl}}" title="IDA" target="_blank">responsible party</a> fills in the notification to the VVT <b>before</b> the start of the processing of personal data. <span class="italic">This is a legally required documentation.</span><b> Relevant documents</b> can be <b>uploaded</b> in the online form.
						</li>
					</ul>
					<p>
						<a href="{{reportingModuleStepByStepEnUrl}}" title="Instruction VVT Report" target="_blank">Here</a> you find the step-by-step instruction to the online form VVT Report.
					</p>
				</div>
			</div>
		</div>
	`,
	styles: [`
		.main-container {
			display: grid;
			grid-template-rows: min-content minmax(100px, 1fr);
			padding: 1em;
			height: 100%;
		}
		.header {
			display: grid;
			grid-template-rows: min-content min-content;
			grid-template-columns: min-content 1fr min-content;
			border-radius: 1em;
		}
		.logo-container {
			padding: 0.5em 1em;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 8em;
		}
		.image {
			width: 5em;
		}
		.news {
			color: #C30F08;
			font-weight: bold;
			margin: 1em 0 1em 0;
		}
		.content {
			display: grid;
			grid-template-rows: max-content max-content;
			grid-template-columns: 1fr 1fr;
			font-size: 1rem;
		}
		.item:nth-child(1) {
			grid-column: 1 / span 2;
			margin: 1em 0 0.5em 0;
			text-align: center;
			line-height: 1.5em;
		}
		.item:nth-child(2) {
			margin: 0.5em 0.5em 0 0;
		}
		.item:nth-child(3) {
			margin: 0.5em 0 0 0.5em;

		}
		.item {
			background-color: #f5f5f5;
			padding: 1em;
			border-radius: 0.5em;
			color: #696969;
		}
		.item p {
			text-align: center;
			font-weight: bold;
			line-height: 1.7em;
		}
		.item a {
			text-decoration: underline;
			font-weight: bold;
			color: #C30F08;
		}
		.item ul li {
			margin: 0 0 0.5em 0;
		}
		.underline {
			text-decoration: underline;
		}
		.italic {
			font-style: italic;
		}
		.title-container {
			display: flex;
			flex: 1 1 auto;
			justify-content: center;
			align-items: center;
			padding: 0 1em;
		}
		.title {
			font-size: 2em;
			text-align: center;
			padding: 0.5em;
			color: gainsboro;
		}
		.app-bar {
			grid-column: 1 / span 3;
		}
	`],
	providers: [
	]
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {

	//private subscription: Subscription;
	appBarItemTypeET = AppBarItemTypeET;

	languageCodeET = LanguageCodeET;

	get languages(): ILanguage[] {
		return this.localizationService.activatedLanguages;
	}

	get currentLanguage(): ILanguage {
		return this.localizationService.currentLanguage;
	}

	title$: Observable<string>;

	decisionTreeDeUrl: string;
	decisionTreeEnUrl: string;
	IdaPageDeUrl: string;
	IdaPageEnUrl: string;

	reportingModuleUrl: string;
	reportingModuleResponsiblePartyDeUrl: string;
	reportingModuleResponsiblePartyEnUrl: string;
	reportingModuleStepByStepDeUrl: string;
	reportingModuleStepByStepEnUrl: string;

	enquiryModuleUrl: string;
	enquiryModuleDataHelpDeskDeUrl: string;
	enquiryModuleDataHelpDeskEnUrl: string;
	enquiryModuleStepByStepDeUrl: string;
	enquiryModuleStepByStepEnUrl: string;

	news_de: string;
	news_en: string;

	anfragenKategorienDe = ['Keine Anfragenkategorien vorhanden'];
	anfragenKategorienEn = ['No request categories available'];

	constructor(
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
		private titleService: ITitleService,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		private privacyNgModuleInfoService: IPrivacyNgModuleInfoService,
		@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler) {
		// this.subscription = this._toolbarService.showInfo$.subscribe(() => this.onShowInfo());
	}

	ngOnInit() {
		this.localizationService.changeHandler.subscribe(() => {
			this.title$ = this.localizationService.getStringFromId('app.application.title');
			this.localizationService.getStringFromId('app.application.browser-title').subscribe(
				res => this.titleService.browserTitle = res
			);
			this.updateModuleUrls();
			// this.titleService.browserTitle = 'GIZ Datenschutz Portal';
			// this.titleService.setBrowserTitleLocaliszationId('app.application.browser-title');
		});
		this.updateModuleUrls();
		// this.titleService.browserTitle = 'GIZ Datenschutz Portal';
		// this.titleService.setBrowserTitleLocaliszationId('app.application.browser-title');

		this.pdAccessService.getExtent(
			'Textvorlage',
			'aktiv="true" && Vorlagenart=18 && Name="Landing-Page-DE"',
			undefined, ['Text']).subscribe(res => {
			if (res.length == 1) {
				this.news_de = res[0].pdObjectRaw['Text'];
			}
		});

		this.pdAccessService.getExtent(
			'Textvorlage',
			'aktiv="true" && Vorlagenart=18 && Name="Landing-Page-EN"',
			undefined,
			['Text']).subscribe(res => {
			if (res.length == 1) {
				this.news_en = res[0].pdObjectRaw['Text'];
			}
		});

		this.pdAccessService.getExtent(
			'Anfragenkategorie',
			'to_Unternehmen.Name == "Gesellschaft für Internationale Zusammenarbeit"',
			undefined,
		).pipe(
			tap(kategorien => {
				if (kategorien?.length >= 0) {
					this.anfragenKategorienDe = kategorien.map(kategorie => kategorie.pdObjectRaw['Bezeichnung']);
					this.anfragenKategorienEn = kategorien.map(kategorie => kategorie.pdObjectRaw['Bezeichnung_en']);
				}
			})
		).subscribe();
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		/*if (this.subscription) {
			this.subscription.unsubscribe();
		}*/
	}
	onLanguageButtonSelectionChanged(state: boolean, lang: ILanguage): void {
		if (!state) {
			return;
		}
		this.localizationService.setCurrentLanguage(lang.code);
	}

	private updateModuleUrls(): void {1
		let createUrl = (url: string) => {
			url += url.includes('?') ? '&' : '?';
			return url + 'lang=' + this.currentLanguage.shortName;
		}

		let methodName = this.privacyNgModuleInfoService.moduleRequestMethod;
		this.pdAccessService.callOperation(methodName, ['Custom','landing-page', 'false', 'getLandingPageUrls', undefined], undefined).subscribe(res => {
			if (Array.isArray(res.outStrs) && res.outStrs.length == 2 && !res.outStrs[1]) {
				let result = JSON.parse(res.outStrs[0]);
				if (result.success) {

					this.decisionTreeDeUrl = result.result.decisionTreeDeUrl;
					this.decisionTreeEnUrl = result.result.decisionTreeEnUrl;
					this.IdaPageDeUrl = result.result.IdaPageDeUrl;
					this.IdaPageEnUrl = result.result.IdaPageEnUrl;

					this.reportingModuleUrl = createUrl(result.result.reportingModuleUrl);
					this.reportingModuleResponsiblePartyDeUrl = result.result.reportingModuleResponsiblePartyDeUrl;
					this.reportingModuleResponsiblePartyEnUrl = result.result.reportingModuleResponsiblePartyEnUrl;
					this.reportingModuleStepByStepDeUrl = result.result.reportingModuleStepByStepDeUrl;
					this.reportingModuleStepByStepEnUrl = result.result.reportingModuleStepByStepEnUrl;

					this.enquiryModuleUrl = createUrl(result.result.enquiryModuleUrl);
					this.enquiryModuleDataHelpDeskDeUrl = result.result.enquiryModuleDataHelpDeskDeUrl;
					this.enquiryModuleDataHelpDeskEnUrl = result.result.enquiryModuleDataHelpDeskEnUrl;
					this.enquiryModuleStepByStepDeUrl = result.result.enquiryModuleStepByStepDeUrl;
					this.enquiryModuleStepByStepEnUrl = result.result.enquiryModuleStepByStepEnUrl;

					return;
				}
			}
			this.errorHandler.handleError(<IError>{ details: `Landingpage urls not found in handleNgModuleRequest()` });
		});
	}

}
